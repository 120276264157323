import { registerFile } from '../../services/API/file';
import { uuidv4 } from './Utils';

export function isDataImageURI(value) {
    return value.startsWith("data:image") && value.includes("base64");
}

export function generateGenericURI(content, mime = "application/octet-stream") {
    return 'data:' + mime + ';base64,' + content;
}

export function base64tobinary(base64) {
	base64 = !base64.includes("base64,") ? base64 : base64.substring(base64.indexOf("base64,") + 7);
    return atob(base64.replace(/\s/g, ''));
}

export function generateURI(file, content) {
	if (content == null)
		return "data:" + file.type + ";base64," + file.binary
    return "data:" + file.type + ";base64," + btoa(content); 
}

export function extractURI(uri, name) {
	var [type, binary] = uri.substring(5).split(";base64,");
	if (!name)
		return { type: type, binary: binary }
    return { name: name, type: type, binary: binary }
}

export function isURI(value) {
  return typeof(value) == "object" && (value?.binary || value?.url);
}

export async function data2url(type, name, data) {
  let id = uuidv4(), ext = null;
  if (type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || type == "application/octet-stream") {
      ext = "docx";
      id += ".docx"
  } else if (type.startsWith("image")) {
      ext = "img";
      id += name.substring(name.lastIndexOf("."));
  } else if (type == "application/pdf") {
      ext = "pdf";
      id += ".pdf"
  } else {
      ext = "media";
      id += name.substring(name.lastIndexOf("."));
  }
  if (ext) {
    let obj = await registerFile(id, data, false);
    return {id, name, type, ext, url: obj.url};
  }
  return {id, name, type, data, ext};
}

export function buildStringLinkURI(value, nameDef = "Arquivo disponível") {
  if (value.includes("data:") && value.includes("base64")) {
        const type = value.substring(5, value.indexOf(";"));
      return <a download={"download." + mime2extension(type)} href={value}>{nameDef}</a>
  } else if (value.includes("https://") || value.includes("http://")) {
    return <a href={value}>{nameDef}</a>
  }
  return <a href={image}>{nameDef}</a>
}

export function buildLinkURI(value, nameDef = "Arquivo disponível", cName = "") {
	if (typeof(value) == "string") {
		if (value.includes("data:") && value.includes("base64")) {
      		const type = value.substring(5, value.indexOf(";"));
	    	return <a className={cName} download={"download." + mime2extension(type)} href={value}>{nameDef}</a>
		} else if (value.includes("https://") || value.includes("http://")) {
      return <a className={cName} href={value}>{nameDef}</a>
    }
	}
	if (typeof(value) == "object") {
		const name = value.name ? value.name : nameDef;
    if (value.binary)
		  return <a className={cName} download={name} href={"data:" + value.type + ";base64," + value.binary}>{name}</a>;
    return <a className={cName} download={name} href={value.url}>{name}</a>;
	}
	return value;
}

export function mime2extension(type) {
    if (type == "application/pdf")
      return "pdf";
    if (type == "image/jpeg")
      return "jpeg";
    if (type == "image/jpg")
      return "jpg";
    if (type == "image/png")
      return "png";
    if (type == "image/svg")
      return "svg";
    if (type == "image/bpm")
      return "bpm";
    if (type.includes("x-msvideo"))
      return "avi";
    if (type.includes("x-flv"))
      return"flv";
    if (type.includes("x-matroska"))
      return"mkv";
    if (type.includes("quicktime"))
      return"mov";
    if (type.includes("mp4"))
      return"mp4";
    if (type.includes("x-ms-wmv"))
      return"wmv";
    return "docx";
}
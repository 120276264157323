export const FLIP_PREVIEW = 'FLIP_PREVIEW';
export const SUBMIT_SEARCH = 'SUBMIT_SEARCH';
export const EXPORT_EXCEL = 'EXPORT_EXCEL';
export const SHARE_LINK = 'SHARE_LINK';
export const REGISTER_URL = 'REGISTER_URL';
export const ITEMS_LIST = 'ITEMS_LIST';
export const ITEMS_CURRENT = 'ITEMS_CURRENT';
export const REGISTER_REFRESHABLE = 'REGISTER_REFRESHABLE';

export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';

export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';

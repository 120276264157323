// @flow
import { mountPayload, appConfig, handleReturn } from '../../config/api';
import { API_POST } from '../../services/RestMethods';

export async function registerFile(id, data, transient) {
  if (id == null)
    return null;
  var payload = mountPayload();
  payload.id = id;
  payload.data = data;
  payload.transient = transient;
  var ret = await API_POST("api/file/register", payload);
  return handleReturn(ret);
}

export async function html2pdf(data) {
  var payload = mountPayload();
  payload.data = data;
  var ret = await API_POST("api/file/html2pdf", payload);
  return handleReturn(ret);
}

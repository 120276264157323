import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { getCurrentCli } from "../../services/Auth/auth.utils";
import { buildLinkURI, base64tobinary, isURI } from "./URI";

import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';

export const downloadContent = (filename, res) => {
  const url = window.URL.createObjectURL(
    new Blob(Array.isArray(res) ? res : [res]),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    filename,
  );
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

export const cleanFieldProperty = (str) => {
  return str.replaceAll("$","").replaceAll(".","");
}

export const ans2string = (value, withNormValue, labelURI, classURI) => {
    if (value === undefined || value === null)
        return "";
    if (typeof(value) == "boolean")
        return value ? "Sim" : "Não";
    if (value.toLocaleString().startsWith("data:image"))
        return <div style={{background: "white"}}><img src={value} style={{maxWidth: "40%", marginBottom: 10}}/></div>
    if (Array.isArray(value))
        return joinWithSeparator(value, ", ", " e "); 
    if (isURI(value))
      return buildLinkURI(value, labelURI, classURI);
    if (typeof(value) == "object") {
      if (withNormValue)
        return normValue(value);  
      else
        return JSON.stringify(value);
    }
    return buildLinkURI(value.toLocaleString(), labelURI, classURI);
}

export const ans2norm = (value) => {
    if (value === undefined || value === null)
        return "";
    if (typeof(value) == "boolean")
        return value ? "Sim" : "Não";
    if (Array.isArray(value))
        return joinWithSeparator(value, ", ", " e "); 
    if (isURI(value))
        return value;
    return value.toLocaleString();
}

export const ans2struct = (answers) => {
    let values = {};
    for (let i in answers) {
        const vals = Object.keys(answers[i]);
        for (let j in vals) {
            let k = vals[j];
            if (k.includes(">"))
              k = k.split(">")[1];
            values[k] = ans2norm(answers[i][vals[j]])
        }
    }
    return values;
}

export const ans2vect = (answers) => {
    let values = [];
    for (let i in answers) {
        const vals = Object.keys(answers[i]);
        for (let j in vals) {
          let k = vals[j];
          if (k.includes(">"))
            k = k.split(">")[1];
          values.push({[k]:answers[i][vals[j]]})
        }
    }
    return values;
}

export const base64toBlob = (data, format) => {
    const bytes = base64tobinary(data);
    let length = bytes.length;
    let out = new Uint8Array(length);
    while (length--) 
        out[length] = bytes.charCodeAt(length);
    return new Blob([out], { type: format });
}

export const getLocation = async () => {
  const position = await new Promise(function(resolve, reject) {
    navigator.geolocation.getCurrentPosition(resolve, reject, {
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    });
  }); 
  return [position.coords?.latitude.toFixed(7), position.coords?.longitude.toFixed(7)]
}

export const getIpv4 = async () => {
  const publicIp = require('public-ip');
  try {
    return await publicIp.v4();
  } catch (error) {
    return null;
  }
}

export const getIpv6 = async () => {
  const publicIp = require('public-ip');
  try {
    return await publicIp.v6();
  } catch (error) {
    return null;
  }
}

export const normValue = (values) => {
  if (typeof(values) != "object")
      return values;
  let val = []
  for (let key in values) {
      const lbl = key.substring(key.indexOf(">")+1);
      val.push(<tr><th style={{border:"1px solid #DDD", padding: 4, width: 333}}>{lbl}</th><td style={{border:"1px solid #DDD", padding: 4}}>{values[key]}</td></tr>);
  }
  return <table style={{width: "100%", background: "white"}}><tbody>{val}</tbody></table>;
}

export const joinWithSeparator = (value, defaultSep, lastSep) => {
  if (Array.isArray(value)) {
    let vals = [];
    for (let i in value) 
      vals.push(normValue(value[i]))
    if (vals.length == 0)
        return "";
    if (vals.length == 1)
        return vals[0];
    return vals.slice(0, vals.length - 1).join(defaultSep) + lastSep + vals[vals.length - 1];
  }
  return normValue(value);
}

export const success = (msg) => {
  toast.success(msg, {
    toastId: "id" + hashCode(""+msg),
    position: toast.POSITION.TOP_CENTER
  })
}

export const fail = (msg) => {
  toast.error(msg, {
    toastId: "id" + hashCode(""+msg),
    position: toast.POSITION.TOP_CENTER
  })
}

export const registerId = (id, label, row) => {
  const normalize = (l) => l?.includes("|") ? l.substring(0, l.indexOf("|")) : l;
  if (Array.isArray(label))
    label = joinWithSeparator(label.map(term=>normalize(term)), ", ", " e ");
  else
    label = normalize(label);
  if (row)
    sessionStorage.setItem("_head", "{\"id\":\"" + id + "\", \"label\": \"" + label + "\", \"row\":" + JSON.stringify(row) + "}");
  else
    sessionStorage.setItem("_head", "{\"id\":\"" + id + "\", \"label\": \"" + label + "\"}")
}

export const redirect = (url, self) => {
  if (self)
    window.open(url?.startsWith("http") ? url : "http://" + url, "_self");
  else
    window.open(url?.startsWith("http") ? url : "http://" + url);
}

export const goto = (id, label, row) => {
  registerId(id, label, row);
  return window.location.pathname;
}

export const mountFrontendURL = (uri) => {
    let url = window.location.protocol + "//" + window.location.host + (uri.startsWith("/") ? uri : ("/" + uri));
    if (uri.includes("/public/")) {
      const suffix = "&cli=" + getCurrentCli();
      if (url.includes("?"))
        url = url.replace("?", "?" + suffix + "&");
      else
        url = url + "?" + suffix;
    }
    return url;
}

export const isPublic = () => {
  return window.location.pathname.startsWith("/public");
}

export const isPublicURL = () => {
    const pathname = window.location.pathname;
    return pathname.startsWith("/public") || pathname.includes("login") || pathname.includes("signup") || pathname.includes("recover-pass");
}

export const getParam = (param) => {
    if (window.location.search.includes(param + "=")) {
      let lastIdxKind = window.location.search.lastIndexOf(param + "=");
      var ret = window.location.search.substring(lastIdxKind + param.length + 1);
      if (!ret.includes("&"))
        return ret;
      return ret.substring(0, ret.indexOf("&"));
    }
    return null;
}

export const getId = () => {
  const _head = sessionStorage.getItem("_head");
  if (!_head)
    return null;
  const head = JSON.parse(_head);
  return head.id;
}

export const getRow = () => {
  const _head = sessionStorage.getItem("_head");
  if (!_head)
    return null;
  const head = JSON.parse(_head);
  return head.row;
}

export const getLabel = () => {
  const _head = sessionStorage.getItem("_head");
  if (!_head)
    return null;
  const head = JSON.parse(_head);
  return head.label;
}

export const killId = () => {
  sessionStorage.removeItem("_head")
}

export const today = () => {
  return new Date();
}

export const getLastWeek = () => {
  var today = new Date();
  var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  return lastWeek;
}

export const isDate = (input) => {
    if ( Object.prototype.toString.call(input) === "[object Date]" ) {
      if (isNaN(input.getTime())) {
        return false;   
      } else {
        return true;
      }  
    } 
    return false;   
};

export const newDate = (str) => {
	if (isDate(str))
		return str;
  if (typeof(str) != "string")
      return null;
  if (/^((\d{2})\/(\d{2}\/\d{4})).*$/.test(str)) {
      var datastr = str.substring(0, 10);
      var timestr = str.substring(10);
      const pieces = datastr.split("/");
      datastr = pieces[2] + "-" + pieces[1] + "-" + pieces[0];
      str = datastr + timestr;
  }
  if (!/^(\d{4})-(\d{2})-(\d{2})$/.test(str))
    if (!/^(\d{4})-(\d{2})-(\d{2})(T|\s)(\d{2})\:(\d{2})\$/.test(str))
      if (!/^(\d{4})-(\d{2})-(\d{2})(T|\s)(\d{2})\:(\d{2})\:(\d{2})$/.test(str))
      	if (!/^(\d{4})-(\d{2})-(\d{2})(T|\s)(\d{2})\:(\d{2})\:(\d{2})\.(\d+)[Z]?$/.test(str))
            return null;
  return new Date(str);
}

export const newLocalDate = (str) => {
	const d = newDate(str);
  if (d == null)
      return null;
  if (isDate(d))
      return toLocalTimeZone(d);
  return d;
}

export const newGMTDate = (str) => {
	const d = newDate(str);
  if (d == null)
      return null;
  if (isDate(d))
      return toGMT(d);
  return d;
}

export const toGMT = (d) => {
  if (!d)
      return null;
	return new Date(d.getTime() - new Date().getTimezoneOffset()*60000);
}

export const toLocalTimeZone = (d) => {
  if (!d)
      return null;
	return new Date(d.getTime() + new Date().getTimezoneOffset()*60000);
}

export const getGMT = (zone) => {
    return Math.abs(zone) < 10 ? (zone > 0 ? "" : "-") + "0" + Math.round(Math.abs(zone)) + ":00" : Math.round(zone) + ":00"
}

export const expandedDateTime = (data, withWeek) => {
  if (!data)
    return "";
  var extenso;
  var day = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"][data.getDay()];
  var date = data.getDate();
  var month = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"][data.getMonth()];
  var year = data.getFullYear();
  return withWeek ? `${day}, ${date} de ${month} de ${year}, ` + data.toLocaleTimeString() : `${date} de ${month} de ${year}, ` + data.toLocaleTimeString()
}

export const cartesian = (a, b, ...c) => (b ? cartesian(f(a, b), ...c) : a); 

export const isObject = (v) => {
    return typeof(v) == "object" && Object.keys(v).length > 0 && v !== null;
}

export const isEmptyOrNull = (v) => {
    return v == null || (typeof(v) == "object" && Object.keys(v).length == 0) || (Array.isArray(v) && v.length == 0);
}

const f = (a, b) => [].concat(...a.map(d => b.map(e => [].concat(d, e)))); 

function lc(v) {
  return (v+"").toLowerCase();
}

function localCompare(left, right) {
  if (left == undefined && right == undefined)
    return 0;
  if (typeof(left) == "string" || typeof(right) == "string") {
      left = lc(left);
      right = lc(right);
  }
  if (isNumber(left) && isNumber(right))
      return new Number(left) - new Number(right);
  if (isDate(left) && isDate(right))
      return left - right;
  return left.localeCompare(right);
}

const containerWrapper = (l, r, right, func) => {
  if (Array.isArray(right)) {
    for (let i in right) {
      if (func(l, right[i])) {
        return true;        
      }
    }
    return false;
  }
  return func(l, r);
}

const isTrue = (l, r, right) => containerWrapper(l, r, right, (l, r) => (l == true || localCompare(l, "Sim") == 0));
const isFalse = (l, r, right) => containerWrapper(l, r, right, (l, r) => (l == false || localCompare(l, "Não") == 0));
const isEqual = (l, r, right) => containerWrapper(l, r, right, (l, r) => (localCompare(l, r) == 0));
const isDifferent = (l, r, right) => containerWrapper(l, r, right, (l, r) => (localCompare(l, r) != 0));
const contains = (l, r, right) => containerWrapper(l, r, right, (l, r) => (lc(getViewVal(l)).includes(lc(getViewVal(r)))));
const ncontains = (l, r, right) => containerWrapper(l, r, right, (l, r) => (!lc(getViewVal(l)).includes(lc(getViewVal(r)))));
const hasValue = (l, r, right) => containerWrapper(l, r, right, (l, r) => (l != undefined && l != ""));
const notHasValue = (l, r, right) => containerWrapper(l, r, right, (l, r) => (l == undefined || l == ""));
const isGt = (l, r, right) => containerWrapper(l, r, right, (l, r) => (localCompare(l, r) > 0));
const isLt = (l, r, right) => containerWrapper(l, r, right, (l, r) => (localCompare(l, r) < 0));
const isGeq = (l, r, right) => containerWrapper(l, r, right, (l, r) => (localCompare(l, r) >= 0));
const isLeq = (l, r, right) => containerWrapper(l, r, right, (l, r) => (localCompare(l, r) <= 0));

export const getComparisons = () => ({
  "É verdadeiro": (l, r, right) => isTrue(l, r, right),
  "É falso": (l, r, right) => isFalse(l, r, right),
  "É igual a": (l, r, right) => isEqual(l, r, right),
  "É diferente de": (l, r, right) => isDifferent(l, r, right),
  "Contém": (l, r, right) => contains(l, r, right),
  "Não contém": (l, r, right) => ncontains(l, r, right),
  "Tem valor": (l, r, right) => hasValue(l, r, right),
  "Não tem valor": (l, r, right) => notHasValue(l, r, right),
  "É maior que": (l, r, right) => isGt(l, r, right),
  "É menor que": (l, r, right) => isLt(l, r, right),
  "É maior ou igual a": (l, r, right) => isGeq(l, r, right),
  "É menor ou igual a": (l, r, right) => isLeq(l, r, right)
});


export const isBinary = (cmp) => {
  return cmp && ["É igual a", "É diferente de", "Contém", "Não contém", "É maior que", "É menor que", "É maior ou igual a", "É menor ou igual a"]
    .includes(cmp)
} 

export const getTypeComparisons = (type) => {
  if (type?.includes("#"))
    type = type.split("#")[0];
  if (["byte[]", "signature", "picture"].includes(type))
    return {
      "Tem valor": ((l, r)=>(l != undefined && l != "")),
      "Não tem valor": ((l, r)=>(l == undefined || l == "")),
    }
  if (type == "text") {
    return {
      "Tem valor": ((l, r)=>(l != undefined && l != "")),
      "Não tem valor": ((l, r)=>(l == undefined || l == "")),
      "É igual a": ((l, r)=>(localCompare(l, r) == 0)),
      "É diferente de": ((l, r)=>(localCompare(l, r) != 0)),
      "Contém": ((l, r)=>(lc(getViewVal(l)).includes(lc(getViewVal(r))))),
      "Não contém": ((l, r)=>(!lc(getViewVal(l)).includes(lc(getViewVal(r)))))
    }
  }
  if (type == "boolean") {
    return {
      "Tem valor": ((l, r)=>(l != undefined && l != "")),
      "Não tem valor": ((l, r)=>(l == undefined || l == "")),
      "É verdadeiro": ((l, r)=>(l == true || localCompare(l, "Sim") == 0)),
      "É falso": ((l, r)=>(l == false && localCompare(l, "Não") == 0))
    }
  }
  if (type == "number") {
    return {
      "Tem valor": ((l, r)=>(l != undefined && l != "")),
      "Não tem valor": ((l, r)=>(l == undefined || l == "")),
      "É igual a": ((l, r)=>(localCompare(l, r) == 0)),
      "É diferente de": ((l, r)=>(localCompare(l, r) != 0)),
      "É maior que": ((l, r)=>(localCompare(l, r) > 0)),
      "É menor que": ((l, r)=>(localCompare(l, r) < 0)),
      "É maior ou igual a": ((l, r)=>(localCompare(l, r) >= 0)),
      "É menor ou igual a": ((l, r)=>(localCompare(l, r) <= 0))
    }
  }
  if (type == "daterange") {
    return {
      "Contém": ((l, r)=>false), // TODO
      "Não contém": ((l, r)=>false) // TODO
    }
  }
  if (type == "date") {
    return {
      "Tem valor": ((l, r)=>(l != undefined && l != "")),
      "Não tem valor": ((l, r)=>(l == undefined || l == "")),
      "É igual a": ((l, r)=>(localCompare(l, r) == 0)),
      "É diferente de": ((l, r)=>(localCompare(l, r) != 0)),
      "É maior que": ((l, r)=>(localCompare(l, r) > 0)),
      "É menor que": ((l, r)=>(localCompare(l, r) < 0)),
      "É maior ou igual a": ((l, r)=>(localCompare(l, r) >= 0)),
      "É menor ou igual a": ((l, r)=>(localCompare(l, r) <= 0))
    }
  }
  if (type == "time") {
    return {
      "Tem valor": ((l, r)=>(l != undefined && l != "")),
      "Não tem valor": ((l, r)=>(l == undefined || l == "")),
      "É igual a": ((l, r)=>(localCompare(l, r) == 0)),
      "É diferente de": ((l, r)=>(localCompare(l, r) != 0)),
      "É maior que": ((l, r)=>(localCompare(l, r) > 0)),
      "É menor que": ((l, r)=>(localCompare(l, r) < 0)),
      "É maior ou igual a": ((l, r)=>(localCompare(l, r) >= 0)),
      "É menor ou igual a": ((l, r)=>(localCompare(l, r) <= 0))
    }
  }
  if (type == "option") {
    return {
      "É igual a": ((l, r)=>(localCompare(l, r) == 0)),
      "É diferente de": ((l, r)=>(localCompare(l, r) != 0))
    }
  }
  if (type == "option[]") {
    return {
      "Tem valor": ((l, r)=>(l != undefined && l != "")),
      "Não tem valor": ((l, r)=>(l == undefined || l == "")),
      "Contém": ((l, r)=>(lc(getViewVal(l)).includes(lc(getViewVal(r))))),
      "Não contém": ((l, r)=>(!lc(getViewVal(l)).includes(lc(getViewVal(r)))))
    }
  }
  if (type == "switcher")
    return {
      "Tem valor": ((l, r)=>(l != undefined && l != "")),
      "Não tem valor": ((l, r)=>(l == undefined || l == "")),
      "É igual a": ((l, r)=>(localCompare(l, r) == 0)),
      "É diferente de": ((l, r)=>(localCompare(l, r) != 0))
    };
  return getComparisons();
}

export const localeOptions = {
    direction: 'ltr',
    format: "DD/MM/YYYY",
    separator: ' - ',
    applyLabel: 'Aplicar',
    cancelLabel: 'Cancelar',
    fromLabel: "De",
    toLabel: "Até",
    drops: "left",
    weekLabel: 'S',
    customRangeLabel: 'Selecionar dias',
    daysOfWeek: [
        "Dom",
        "Seg",
        "Ter",
        "Qua",
        "Qui",
        "Sex",
        "Sab"
    ],
    monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
    ],
    firstDay: 1
}

const colection2text = (v) => {
    if (Array.isArray(v))
        return v.join("\n");
    else if (typeof(v) == "object") {
        var ret = [];
        for (let i in v)
            ret.push(i + ": "+ getViewVal(v[i]));
        return ret.join("\n");
    }
    return v;
}

export const isNumber = (v) => {
  return typeof(v) == "number" || 
    (v != undefined && v != "" && !("" + v).includes(" ") && !isNaN(new Number(v)));
}

export const getCompVal = (v) => {
    if (v === undefined || v === null || v == "")
      return "";
    var str_v = "" + v;
    if (str_v.startsWith("string@#") || str_v.startsWith("link@#"))
      return str_v;
    if (str_v.startsWith("boolean@#"))
      return str_v.substring(9) == "boolean@#true" ? true : false;
    if (str_v.startsWith("number@#"))
      return new Number(str_v.substring(8));
    if (isNumber(v))
        return new Number(v);
    if (str_v.startsWith("datetime@#")) {
      let candDate = newDate(str_v.substring(10));
      if (isDate(candDate))
        return candDate.toJSON();
      return "";
    }
    if (typeof(v) == "boolean")
        return v === true ? "Sim" : "Não";
    let candDate = newDate(v);
    if (isDate(candDate))
        return candDate.toJSON();
    if (Array.isArray(v))
        return v.join(", ");
    return colection2text(v);
}

export const getViewVal = (v) => {
    if (v === undefined || v === null || v == "")
        return "";
    var str_v = "" + v;
    if (str_v.startsWith("string@#") || str_v.startsWith("link@#"))
      return str_v.substring(8);
    if (str_v.startsWith("number@#"))
      return new Number(str_v.substring(8)).toLocaleString();
    if (str_v.startsWith("boolean@#"))
      return str_v.substring(9) == "boolean@#true" ? "Sim" : "Não";
    if (str_v.startsWith("datetime@#")) {
      let candDate = newDate(str_v.substring(10));
      if (isDate(candDate))
        return candDate.toLocaleDateString() + (v.length <= 10 ? "" : " " + candDate.toLocaleTimeString());;
      return "";
    }
    if (isNumber(v))
      return new Number(v).toLocaleString();
    if (typeof(v) == "boolean")
        return v === true ? "Sim" : "Não";
    let candDate = newDate(v);
    if (isDate(candDate))
        return candDate.toLocaleDateString() + (v.length <= 10 ? "" : " " + candDate.toLocaleTimeString());
    return colection2text(v);
}

export const triggerResize = () => {
    // Opera 8.0+
    const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';
    // Safari 3.0+ "[object HTMLElementConstructor]" 
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;
    // Chrome 1 - 71
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    // Blink engine detection
    const isBlink = (isChrome || isOpera) && !!window.CSS;

    if (isIE) {
        var resizeEvent = window.document.createEvent('UIEvents'); 
        resizeEvent.initUIEvent('resize', true, false, window, 0); 
        window.dispatchEvent(resizeEvent);
    } else
        window.dispatchEvent(new Event('resize'));
}

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const confirmAction = (question, handleConfirm) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div style={{textAlign: "center", background: "#dddddd", padding: 50, borderRadius: 47}}>
          <h3>{question}</h3>
          <p>Clique em "Sim" para confirmar ou "Não" para cancelar</p>
          <button style={{margin:5}} className="btn btn-success"
            onClick={() => {
              handleConfirm();
              onClose();
            }}
          >
            Sim
          </button>
          <button style={{margin:5}} className="btn btn-danger" onClick={onClose}>Não</button>
        </div>
      );
    }
  });
}

export const hashCode = (value) => {
    var hash = 0;
    for (var i = 0; i < value.length; i++) {
        var character = value.charCodeAt(i);
        hash = ((hash<<5)-hash)+character;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

export const getComps = (type) => {
    if (type == "drop")
        return ["fa fa-trash", "rgb(133, 30, 99)"]
    if (type == "new")
        return ["fa fa-plus", "rgb(30, 133, 99)"]
    if (type == "task")
        return ["fa fa-tasks", "rgb(150, 33, 150)"]
    if (type == "signature")
        return ["fa fa-edit", "rgb(33, 243, 150)"]
    if (type == "notification")
        return ["fa fa-bell-o", "rgb(33, 150, 243)"]
    if (type == "message")
        return ["fa fa-envelope-o", "rgb(233, 30, 99)"]
    if (type == "document")
        return ["fa fa-file-text-o", "rgb(243, 190, 33)"]
    if (type == "request")
        return ["fa fa-comments-o", "rgb(30, 233, 99)"]
    return ["fa fa-user", "rgb(99, 99, 99)"]
}

export const variableTypes = {
  /* Existe esse type mas não deveria ser disponibilizado como um input normal por requerer mais autenticações */
  "byte[]#Arquivo": "Arquivo", /* @TODO: Seletor de tipo de arquivo */
  "signature#Assinatura": "Assinatura", 
  "boolean#Botão de Acordo": "Botão de Acordo",
  "option#RadioButton": "Botões de Opção", /* @TODO: Seletor de opções */
  "option[]#Checkbox": "Caixas de Seleção", /* @TODO: Seletor de opções */
  "date#Data e Hora": "Data com horário", /* @TODO: Seletor de limiters de início e fim de data */
  "date#Data": "Data específica", /* @TODO: Seletor de limiters de início e fim de data: */
  "number#Estrela": "Estrelas", /* @TODO: Seletor de número de estrelas */
  "option#Farol": "Farol", /* @TODO: Seletor de 3 opções */
  "picture#Fotografia": "Fotografia", 
  "time#Horário": "Horário",
  "option#Combobox": "Lista de Única Escolha", /* @TODO: Seletor de opções */
  "number#Monetário": "Monetário", /* @TODO: Seletor fixo de moedas */
  "number#Número Fracionário": "Número Fracionário",
  "number#Número Inteiro": "Número Inteiro",
  "switcher#Seletor de Botões": "Seletor de Botões", /* @TODO: um Confirm Action explicando que os ícones devem ser setados nos próximos vértices e todas as arestas serão desse tipo */
  "switcher#Seletor de Imagens": "Seletor de Imagens", /* @TODO: um Confirm Action explicando que os ícones devem ser setados nos próximos vértices e todas as arestas serão desse tipo */
  "boolean#Binário": "Sim/Não",
  "number#Slider": "Slider", /* @TODO: Seletor de limiters de início e fim de número */
  "text#Texto": "Texto Curto",
  "text#Frase": "Texto Longo",
  /* @TODO: Seletor de limiters de início e fim de data */
  /* <option value="daterange#Faixa de Data">Faixa de Data</option> */
  /* @TODO: Seletor de limiters de início e fim de data */
  /* <option value="daterange#Faixa de Data e Hora">Faixa de Data com Horário</option> */
  /* @TODO: Seletor de valores para linhas e colunas, bem como o subtipo. */
  /* <option value="???#Matriz">Matriz de seleção</option> */
  /* @TODO: Seletor de valores para linhas, bem como o subtipo. */
  /* <option value="???#Vector">Vetor de seleção</option> */
  /* @TODO: Seletor de campos do vetor. */
  /* <option value="struct#Vector">Matriz de seleção</option> */
  /* @TODO: Seletor de campos para linhas e colunas da matriz. */
  /* <option value="struct#Matriz">Estrutura matricial</option> */
  /* @TODO: Seletor de valores de extremidade e quantas notas de 1 à X. */
  /* <option value="number#Escala Linear">Escala linear</option> */
  /* @TODO: Seletor de valores de extremidade, quantas notas de 1 à X e quais os quesitos das linhas */
  /* <option value="number#Grade Linear">Grade linear</option>  */
}

export const variableTypesNoAddProps = {
  "byte[]#Arquivo": "Arquivo", /* @TODO: Seletor de tipo de arquivo */
  "date#Data e Hora": "Data com horário", /* @TODO: Seletor de limiters de início e fim de data */
  "date#Data": "Data específica", /* @TODO: Seletor de limiters de início e fim de data: */
  "option#Farol": "Farol", /* @TODO: Seletor de 3 opções */
  "picture#Fotografia": "Fotografia",
  "time#Horário": "Horário",
  "number#Monetário": "Monetário", /* @TODO: Seletor fixo de moedas */
  "number#Número Fracionário": "Número Fracionário",
  "number#Número Inteiro": "Número Inteiro",
  "boolean#Binário": "Sim/Não",
  "text#Texto": "Texto Curto",
  "text#Frase": "Texto Longo"
}
import axios from "axios";
import { appConfig } from "../../config/api";
import { redirect, mountFrontendURL } from '../../components/Utils/Utils';

class AuthService {
  async login(username, password) {    
    return axios
      .post(appConfig.BASE_URL_ROOT + "/signin", {
        username,
        password
      })
      .then(response => {
        const credentials = response.data;
        if (credentials.accessToken) {
          localStorage.removeItem("overcli");
          localStorage.setItem("user", JSON.stringify(credentials));
        }
        return credentials;
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("overcli");
    localStorage.removeItem("menu");
    localStorage.removeItem("areas");
    redirect(mountFrontendURL("login"), true);
  }

  register(username, email, password, hashId) {
    return axios.post(appConfig.BASE_URL_ROOT + "/signup", {
      username,
      email,
      password,
      hashId
    });
  }
}

export default new AuthService();
